import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { useEnvironment } from 'yoshi-flow-editor-runtime';

import { st, classes } from './index.st.css';
import { Cta } from './Cta';
import { Ribbon } from './Ribbon';
import { PlanName } from './PlanName';
import { Price } from './Price';
import { Recurrence } from './Recurrence';
import { Tagline } from './Tagline';
import { Validity } from './Validity';
import { FreeTrial } from './FreeTrial';
import { Footer } from './Footer';
import settingsParams from '../../../settingsParams';

export interface PlanProps {
  plan: PublicPlan;
  recurringPlansExist: boolean;
  freeTrialDaysExist: boolean;
  taglinesExist: boolean;
  validityCyclesExist: boolean;
  highlighted: boolean;
  anyBenefits?: boolean;
  onClick: () => void;
}

export const Plan: React.FC<PlanProps> = ({
  plan,
  recurringPlansExist,
  freeTrialDaysExist,
  validityCyclesExist,
  taglinesExist,
  highlighted,
  onClick,
  anyBenefits,
}) => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const hasTwoDetails = validityCyclesExist && freeTrialDaysExist;
  const blendBgColors = !settings.get(settingsParams.wereNewSettingsOpened);

  return (
    <div data-hook="plan" className={st(classes.plan, { highlighted, blendBgColors })}>
      <div className={classes.planTop}>
        <Ribbon highlighted={highlighted} />
        <PlanName highlighted={highlighted} name={plan.details?.name || ''} />
        <Price highlighted={highlighted} price={plan.paymentOptions?.price} />
        <Recurrence
          highlighted={highlighted}
          paymentOptions={plan.paymentOptions}
          recurringPlansExist={recurringPlansExist}
        />
        <Tagline
          taglinesExist={taglinesExist}
          highlighted={highlighted}
          description={plan.details?.description || ''}
        />
        <div className={st(classes.duration, { hasTwoDetails, mobile: isMobile })}>
          <Validity highlighted={highlighted} plan={plan} />
          <FreeTrial highlighted={highlighted} plan={plan} freeTrialDaysExist={freeTrialDaysExist} />
        </div>
        <div className={classes.ctaWrapper}>
          <Cta plan={plan} highlighted={highlighted} onClick={onClick} />
        </div>
      </div>
      <Footer benefits={plan.details?.benefits ?? []} anyBenefits={anyBenefits} highlighted={highlighted} />
    </div>
  );
};
