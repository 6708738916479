import React, { useState, useCallback } from 'react';
import Collapsible from 'react-collapsible';
import { useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { useEnvironment } from 'yoshi-flow-editor-runtime';

import { st, classes } from './Footer.st.css';
import { ArrowDown } from './ArrowDown';
import { useStylesParams } from '../../../../../hooks';

interface FooterProps {
  benefits: string[];
  highlighted: boolean;
  anyBenefits?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ benefits, anyBenefits, highlighted }) => {
  const { isMobile } = useEnvironment();
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const showPlanBenefits = styles.get(stylesParams.showBenefits);

  if (!showPlanBenefits) {
    return null;
  }
  if (isMobile) {
    return benefits.length > 0 ? (
      <MobileBenefits benefits={benefits} mobile={isMobile} highlighted={highlighted} />
    ) : null;
  } else {
    return anyBenefits ? <Benefits benefits={benefits} mobile={isMobile} highlighted={highlighted} /> : null;
  }
};

interface BenefitsProps {
  benefits: string[];
  highlighted: boolean;
  mobile: boolean;
}

const Benefits: React.FC<BenefitsProps> = React.memo(({ benefits, highlighted, mobile }) => {
  return (
    <ul data-hook="benefits" className={st(classes.benefits, { highlighted, mobile })}>
      {benefits.map((benefit, i) => (
        <li data-hook="benefit" key={i}>
          {benefit}
        </li>
      ))}
    </ul>
  );
});

const flex1 = { flex: 1 };

const MobileBenefits: React.FC<BenefitsProps> = React.memo(({ benefits, highlighted, mobile }) => {
  const [expanded, setExpanded] = useState<boolean>(highlighted);
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [setExpanded, expanded]);

  return (
    <div onClick={toggleExpanded} style={flex1}>
      <Collapsible trigger="" open={expanded} easing="ease-out">
        <Benefits benefits={benefits} mobile={mobile} highlighted={highlighted} />
      </Collapsible>
      <ArrowDown dataHook="expand-benefits" className={st(classes.arrow, { expanded, highlighted })} />
    </div>
  );
});
